import { useAuthentication } from '@netfront/gelada-identity-library';
import { Breadcrumbs, IBreadcrumbItem } from '@netfront/ui-library';
import kebabCase from 'lodash.kebabcase';

import { BreadcrumbItemHomePageLink, BreadcrumbItem } from '../components/BreadcrumbItems';
import { HelpButton } from '../components/HelpButton';
import { Link } from '../components/Link';
import { PageLayout } from '../components/PageLayout';

const PAGE_TITLE = 'Page not found';

const BREADCRUMB_ITEMS: IBreadcrumbItem[] = [
  {
    content: <BreadcrumbItemHomePageLink />,
    key: 'home',
  },
  {
    content: <BreadcrumbItem text={PAGE_TITLE} />,
    key: kebabCase(PAGE_TITLE),
  },
];

const Error404Page = () => {
  const { isAuthenticated } = useAuthentication();

  return (
    <PageLayout title={PAGE_TITLE}>
      <div className="container-md flex justify-end py-8">
        <Breadcrumbs items={BREADCRUMB_ITEMS} />
      </div>

      <div className="container-md pb-8 pt-4">
        <div className="flex flex-col md:flex-row">
          <div className="mb-4 md:mb-0 md:w-4/5 w-full">
            <h1 className="color-accent h4 mb-2 weight-800">404</h1>
            <h2 className="color-blue-900 h2">{PAGE_TITLE}</h2>
            <hr className="bg-grey-100 block border-none h-0-5 ml-0 mt-7 radius-full w-third" role="presentation" />
          </div>

          <div className="align-center flex w-full">
            <div className="bg-grey-100 flex-1 h-0-5 md:flex mx-8 none radius-full" role="presentation" />
            <HelpButton />
          </div>
        </div>
      </div>

      <section className="pb-12 pt-4">
        <div className="container-md flex flex-col md:flex-row">
          <div className="mb-12 md:mb-0 md:mr-24 md:third w-full">
            <p className="mb-8">Sorry, the page you were looking for could not be found.</p>

            <Link href={isAuthenticated ? '/dashboard' : '/'} theme="default">
              {`Return ${isAuthenticated ? 'to dashboard' : 'to homepage'}`}
            </Link>
          </div>

          <div className="flex justify-center md:w-3/4 w-full">
            <img alt="Cartoon of user engaging with the CarerWell program" loading="lazy" src="/images/pre-chapter-illustration.svg" />
          </div>
        </div>
      </section>
    </PageLayout>
  );
};

// eslint-disable-next-line import/no-default-export
export default Error404Page;
